<template>
  <div>
    <AuthForm v-if="Object.keys(data).length > 0" :data="data" v-bind="permision" />
    <div v-else class="noVerify">该用户还未实名认证</div>
  </div>
</template>

<script>
import { PERSONNAL_CERTIFY, COMPANY_CERTIFY } from '@/utils/constant/realNameAuthonst'
import { getByUserId } from '../resellerAPI'
import AuthForm from '@/views/components/authentication/authForm'
export default {
  components: {
    AuthForm
  },
  data() {
    return {
      domPermision: {
        showAuth: true
      }
    }
  },
  props:{
    data:{
      type:[Object, Array],
      default:()=>({})
    }
  },
  computed: {
    permision({ domPermision }) {
      const tmpArr = {
        [PERSONNAL_CERTIFY]: {
          showPersonnal: true
        },
        [COMPANY_CERTIFY]: {
          showEnterprise: true
        }
      }
      return {
        ...domPermision,
        ...tmpArr[this.data.certificateType]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .form-container:last-child {
    border-bottom: none;
  }
}
.noVerify {
  margin: 50px 0 0 20px;
  color: #606266;
}
</style>
